<template>
  <div class="host">
    <div v-if="selectedGame" class="game">
      <nav class="players">
        <h3>Players</h3>
        <ul>
          <li
            v-for="player in players"
            :key="player.uid"
            :class="{ selected: selectedPlayer === player }"
            @click="selectedPlayer = player"
          >
            <span class="name">{{ player.displayName }}</span>
            <span class="badge" v-show="Object.values(player.recent).length">{{
              Object.values(player.recent).length
            }}</span>
          </li>
        </ul>
      </nav>
      <div class="details">
        <h2>{{ selectedGame.name }} (Room Code: {{ room }})</h2>
        <template v-if="selectedPlayer">
          <div class="player">
            <div class="avatar"><img :src="selectedPlayer.photoURL" /></div>
            <h3>{{ selectedPlayer.displayName }}</h3>
          </div>
          <PlayerHand
            :cardComponent="selectedGame.cardComponent"
            :cards="selectedPlayer.hand"
            :selectedCard="selectedHandCard"
            @select="selectedHandCard = $event"
          />
          <div class="buttons">
            <button class="remove" v-if="selectedHandCard !== null" @click="removeFromHand">Remove from Hand</button>
            <button class="add" v-if="selectedDeckCard" @click="addToHand">Add to Hand</button>
          </div>

          <h3>Recently Played</h3>
          <PlayerHand :cardComponent="selectedGame.cardComponent" :cards="selectedPlayer.recent" recent />
          <button v-if="Object.values(selectedPlayer.recent).length" @click="selectedPlayer.recent = {}">Clear</button>
        </template>
        <p v-else>Select a player to view and update their hand.</p>
      </div>
      <div class="deck">
        <h3>Deck</h3>
        <p>Select a card to add to hand.</p>
        <div class="cards">
          <template v-if="Array.isArray(selectedGame.cards[0])">
            <div class="group" v-for="(group, groupIndex) in selectedGame.cards" :key="groupIndex">
              <component
                v-for="(card, index) in group"
                :key="`${card.suit}-${card.value}-${index}`"
                :is="selectedGame.cardComponent"
                :class="{ selected: selectedDeckCard === card }"
                v-bind="card"
                @click="selectedDeckCard === card ? (selectedDeckCard = null) : (selectedDeckCard = card)"
              />
            </div>
          </template>
          <template v-else>
            <component
              v-for="(card, index) in selectedGame.cards"
              :key="`${card.suit}-${card.value}-${index}`"
              :is="selectedGame.cardComponent"
              :class="{ selected: selectedDeckCard === card }"
              v-bind="card"
              @click="selectedDeckCard === card ? (selectedDeckCard = null) : (selectedDeckCard = card)"
            />
          </template>
        </div>
      </div>
    </div>
    <template v-else>
      <h2>Select the game type to host:</h2>
      <ul class="games">
        <li v-for="game in games" :key="game.name">
          <button @click="selectGame(game)">{{ game.name }}</button>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PlayerHand from '../components/PlayerHand.vue';
import { db } from '../firebaseConfig';
import standardDeck from '../games/StandardDeck';
import ticketToRide from '../games/TicketToRide';

export default {
  name: 'Host',
  components: {
    PlayerHand,
  },
  data() {
    return {
      room: '',
      selectedGame: null,
      selectedPlayer: null,
      selectedHandCard: null,
      selectedDeckCard: null,
      players: [],
      games: [standardDeck, ticketToRide],
      hostDeleted: null,
    };
  },
  created() {
    if (this.$route.params.room && !this.selectedGame) {
      console.log('reading once', this.$route.params.room);
      db.ref(`/rooms/${this.$route.params.room}`)
        .once('value')
        .then((snapshot) => {
          const roomData = snapshot.val();
          console.log(roomData);
          if (roomData && roomData.type) {
            this.selectedGame = this.games.find((game) => game.type === roomData.type);
            if (this.selectedGame) this.room = this.$route.params.room;

            // Add player listener
            const playerRef = db.ref(`/room_players/${this.room}`);
            playerRef.on('child_added', this.addPlayer);
            playerRef.on('child_removed', this.removePlayer);

            // const handRef = db.ref(`/hands/${this.room}/${this.currentUser.uid}`);
            // handRef.on('child_removed', this.cardRemoved);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
  beforeDestroy() {
    db.ref(`/room_players/${this.room}`).off();
    this.players.forEach((player) => {
      db.ref(`/hands/${this.room}/${player.uid}`).off();
    });
  },
  computed: {
    ...mapState('user', ['currentUser']),
  },
  methods: {
    selectGame(game) {
      // Generate a new room code
      let room = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < 4; i += 1) {
        room += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      const roomData = {
        type: game.type,
        hostUID: this.currentUser.uid,
      };
      console.log('setting room', roomData);
      db.ref(`rooms/${room}`).set(roomData);
      this.room = room;
      this.selectedGame = game;
      this.$router.replace({
        name: 'host',
        params: {
          room,
        },
      });
    },
    addPlayer(snapshot) {
      // const data = snapshot.val();
      db.ref(`users/${snapshot.key}`)
        .once('value')
        .then((userSnapshot) => {
          const user = { hand: {}, recent: {}, ...userSnapshot.val() };

          const handRef = db.ref(`hands/${this.room}/${snapshot.key}`);
          handRef.once('value').then((handSnapshot) => {
            const hand = handSnapshot.val();
            // user.hand = Object.keys(hand).map((key) => ({ ...hand[key], key }));
            user.hand = hand || {};
            this.players.push(user);
            console.log('add player', user);
          });
          handRef.on('child_removed', this.cardRemoved);
        });
    },
    removePlayer(snapshot) {
      const userID = snapshot.key;
      const index = this.players.findIndex((p) => p.uid === userID);
      if (index > -1) {
        this.players.splice(index, 1);
        this.selectedPlayer = null;
      }
    },
    addToHand() {
      if (this.selectedDeckCard) {
        const cardRef = db.ref(`hands/${this.room}/${this.selectedPlayer.uid}`).push(this.selectedDeckCard);
        this.$set(this.selectedPlayer.hand, cardRef.key, {
          ...this.selectedDeckCard,
        });
        // db.ref(`room_players/${this.room}/${this.currentUser.uid}/hand`).push(this.selectedDeckCard);
        this.selectedDeckCard = null;
      }
    },
    removeFromHand() {
      if (this.selectedHandCard) {
        // const index = this.selectedPlayer.hand.findIndex((card) => card.key === this.selectedHandCard.key);
        // if (index > -1) {
        //   this.selectedPlayer.hand.splice(index, 1);
        // this.$delete(this.selectedPlayer.hand, this.selectedHandCard);
        this.hostDeleted = this.selectedHandCard;
        db.ref(`hands/${this.room}/${this.selectedPlayer.uid}/${this.selectedHandCard}`).remove();
        // }
      }
      this.selectedHandCard = null;
    },
    cardRemoved(snapshot) {
      const cardKey = snapshot.key;
      const userUID = snapshot.ref.parent.key;
      const player = this.players.find((p) => p.uid === userUID);
      if (player) {
        console.log(userUID, player, cardKey, this.hostDeleted);
        if (this.hostDeleted !== cardKey) {
          this.$set(player.recent, cardKey, { ...player.hand[cardKey] });
        }
        this.$delete(player.hand, cardKey);
      }
      console.log('card removed', userUID, cardKey);
    },
  },
};
</script>

<style scoped lang="scss">
h2 {
  margin-top: 0;
}
.game {
  display: flex;
  height: 100%;
}
.players {
  border-right: 1px solid black;
  margin-left: -40px;

  h3 {
    margin-top: 0;
    padding-left: 20px;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px 20px;

    &.selected {
      background-color: #ccc;
    }
    .badge {
      font-size: 14px;
      text-align: center;
      border: 1px solid black;
      border-radius: 50%;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      margin-left: 10px;
    }
  }
}
.details {
  flex-grow: 1;
  border-left: 1px solid black;
  border-right: 1px solid black;
  margin-left: -1px;
  padding-left: 20px;
  padding-right: 20px;

  .buttons {
    display: flex;
    margin-top: 30px;
  }
  button {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    display: block;

    &.add {
      margin-left: auto;
    }
  }
}
.player {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .avatar {
    border-radius: 50%;
    margin-right: 20px;
    overflow: hidden;
    width: 50px;
    height: 50px;

    img {
      display: block;
      width: 100%;
    }
  }
}
.deck {
  border-left: 1px solid black;
  margin-left: -1px;
  padding-left: 20px;
  max-width: 40%;

  h3 {
    margin-top: 0;
  }
}
</style>
